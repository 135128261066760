import { FC } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";

interface IGlass {
  isMobile: boolean;
  setShowNotepad: () => void;
}

const Glass: FC<IGlass> = ({ isMobile, setShowNotepad }) => {
  return (
    <>
      <Flex
        position={"fixed"}
        top="0"
        zIndex={3}
        h="4rem"
        w="100%"
        bg="linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));"
        backdropFilter="blur(10px)"
        boxShadow="0 0 20px rgba(255, 255, 255, 0.3), 0 2px 6px rgba(0, 0, 0, 0.1)"
        justify="start"
        align="center"
        p={["2rem", "2rem"]}
      >
        {isMobile && (
          <ArrowUpDownIcon
            cursor="pointer"
            boxSize={["1rem", "2rem"]}
            onClick={setShowNotepad}
          />
        )}
      </Flex>
      {!isMobile && (
        <Flex
          position="fixed"
          left="0"
          zIndex={3}
          h="100%"
          w="36%"
          bg="linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));"
          backdropFilter="blur(10px)"
          boxShadow="0 0 20px rgba(255, 255, 255, 0.3), 0 2px 6px rgba(0, 0, 0, 0.1)"
        />
      )}
      <Flex
        position="fixed"
        justify="end"
        align="center"
        bottom="0"
        zIndex={3}
        h="4rem"
        w="100%"
        bg="linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0));"
        backdropFilter="blur(10px)"
        color="black"
        fontSize="xs"
        fontWeight="thin"
        boxShadow="0 0 20px rgba(255, 255, 255, 0.3), 0 2px 6px rgba(0, 0, 0, 0.1)"
      >
        <Box pr="2rem">Anthony Lu - 2023</Box>
      </Flex>
    </>
  );
};

export default Glass;
