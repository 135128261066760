import { Box, Flex, Link } from "@chakra-ui/react";
import TechStack from "../../components/layout/techStack";
import { SiTypescript, SiReact, SiChakraui } from "react-icons/si";
import ImageSlider from "../../components/imageSlider/imageSlider";
import SOne from "./screenshots/1.png";
import STwo from "./screenshots/2.png";

const RamsgateChinese = () => {
  return (
    <Flex direction="column" align="center" gap="4rem">
      <Flex direction="column" align="center" gap="2rem" justify="center">
        <Box fontSize="2xl" fontWeight="bold">
          Ramsgate Chinese Medicine Clinic (PROJECT)
        </Box>
        <Box fontWeight="light" fontSize="sm">
          1st February, 2024
        </Box>
        <Box>
          <Box fontWeight="bold" display="inline">
            Description:
          </Box>{" "}
          A solo project that I completed for my friend who needed a new website
          for her Chinese Medicine Clinic.
        </Box>

        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            URL:
          </Box>{" "}
          <Link href="https://ramsgatefertilityclinic.com.au/" isExternal>
            https://ramsgatefertilityclinic.com.au/
          </Link>
        </Box>
      </Flex>
      <TechStack
        items={[
          { icon: SiTypescript, skillName: "Typescript" },
          { icon: SiReact, skillName: "React" },
          { icon: SiChakraui, skillName: "ChakraUi" },
        ]}
      />
      <Box fontWeight="bold" fontSize="xl" w="100%">
        Screenshots:{" "}
      </Box>
      <ImageSlider slides={[{ image: SOne }, { image: STwo }]} />
      <Box w="100%">
        <Box fontWeight="bold" display="inline">
          Final Comments:
        </Box>{" "}
        The project was simple with minimal requirements and was deployed
        without any complications. It was a pleasure to use my skills for a
        friend and I would highly recommend her services.
      </Box>
    </Flex>
  );
};

export default RamsgateChinese;
