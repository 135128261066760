import { Box, Flex, Image } from "@chakra-ui/react";
import React, { FC } from "react";
import Goal from "../../components/layout/goal";
import TechStack from "../../components/layout/techStack";
import { SiTypescript, SiReact, SiOpenai } from "react-icons/si";
import SOne from "./screenshots/1.png";
import STwo from "./screenshots/2.png";
import SThree from "./screenshots/3.png";

const OpenAiFoodGenerator: FC = () => {
  return (
    <Flex direction="column" align="center" gap="4rem">
      <Flex direction="column" align="center" gap="2rem" justify="center">
        <Box fontSize="2xl" fontWeight="bold">
          Open Ai Food Generator (PROJECT)
        </Box>
        <Box fontWeight="light" fontSize="sm">
          3rd July, 2023
        </Box>
        <Goal
          goal={
            "Create an app that is powered by ai which will tell the user a meal that they can make using the ingredients that the user inputs."
          }
          url={"https://main--charming-cocada-8f0a6c.netlify.app/"}
          github="https://github.com/AnthonyALu/openai-food-generator"
        />
      </Flex>
      <TechStack
        items={[
          { icon: SiTypescript, skillName: "Typescript" },
          { icon: SiReact, skillName: "React" },
          { icon: SiOpenai, skillName: "Openai" },
        ]}
      />
      <Box>Enter some ingredients you have in the fridge...</Box>
      <Flex direction={["column", "row"]}>
        <Image src={SOne} /> <Image src={STwo} />
      </Flex>
      <Box>...and let Steve handle the rest!</Box>
      <Image src={SThree} />
      <Box fontWeight="bold">
        Disclaimer: Steve will stop working once all his credits have been used.
        Steve also likes to make up his own recipe links.
      </Box>
    </Flex>
  );
};

export default OpenAiFoodGenerator;
