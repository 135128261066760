import { Box, Flex, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { FC } from "react";

interface IGoal {
  goal: string;
  url: string;
  github: string;
}

const Goal: FC<IGoal> = ({ goal, url, github }) => {
  return (
    <Flex direction="column" gap="2rem">
      <Box fontWeight="light" fontSize="sm">
        <Box fontWeight="bold" display="inline">
          Goal:{" "}
        </Box>
        {goal}
      </Box>
      <Link href={github} isExternal textAlign="center">
        {github} <ExternalLinkIcon mx="2px" />
      </Link>
      <Link href={url} isExternal textAlign="center">
        {url} <ExternalLinkIcon mx="2px" />
      </Link>
    </Flex>
  );
};

export default Goal;
