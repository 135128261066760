import { Box, CloseButton, Flex, Link } from "@chakra-ui/react";
import { FC, useState } from "react";
import Search from "./search";
import ToDoContent from "./todoContent";
import { Note, notesData } from "./notesData";

interface INotepad {
  isMobile: boolean;
  setShowNotepad: () => void;
}

const Notepad: FC<INotepad> = ({ isMobile, setShowNotepad }) => {
  const [currentNote, setCurrentNote] = useState<Note>(notesData[0]);
  const [searchFilter, setSearchFilter] = useState("");
  return (
    <Flex direction="column" w={["100%", "30%"]}>
      <Flex
        position="fixed"
        border="1px solid gray"
        background="gray.800"
        maxW={["80%", "80%", "80%", "30%"]}
        top="50%"
        maxH="80%"
        left={["50%", "50%", "50%", "20%"]}
        transform="translate(-50%, -50%)"
        borderRadius="8px"
        boxShadow="0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1)"
        color="white"
        zIndex={10}
      >
        <Flex borderRadius="8px" w="100%">
          <Flex direction="column" w="36%">
            <Flex
              bg="gray.700"
              borderRadius="8px 0px 0px 0px"
              h="4rem"
              borderRight="1px solid black"
              p="1rem"
              align="center"
              fontSize="2xl"
              fontWeight="thin"
            >
              Anthony
            </Flex>
            <Box
              borderRight="1px solid black"
              flex={1}
              maxH="32rem"
              overflow="hidden"
            >
              <ToDoContent
                setCurrentNote={setCurrentNote}
                searchFilter={searchFilter}
              />
            </Box>
          </Flex>
          <Flex direction="column" flex={1}>
            <Flex
              align="center"
              justify="end"
              bg="gray.700"
              p="1rem"
              borderRadius="0px 8px 0px 0px"
              h="4rem"
              borderRight="1px solid black"
            >
              <Box maxW="8rem">
                <Search
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                />
              </Box>
              {isMobile && <CloseButton onClick={setShowNotepad} />}
            </Flex>
            <Flex
              p="1rem"
              direction="column"
              gap="1rem"
              flex={1}
              overflow="auto"
            >
              <Flex justify="center" fontSize="xs" opacity={0.8}>
                {currentNote.date}
              </Flex>
              <Box fontWeight="bold">{currentNote.heading}</Box>
              <Box>{currentNote.summary}</Box>
              {currentNote.status && <Box>Status: {currentNote.status}</Box>}
              {currentNote.link && (
                <Link href={currentNote.link} isExternal>
                  Link: {currentNote.link}
                </Link>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Notepad;
