export type Note = {
  heading: string;
  summary: string;
  date: string;
  status?: string;
  link?: string;
};

export const notesData: Note[] = [
  {
    heading: "About",
    summary:
      "Hi! My name is Anthony and I am a developer based in Sydney. This is where I keep a summary of all my minor projects and here is my list of things I have done or am planning on doing.",
    date: "4/7/2023",
  },
  {
    heading: "Contact",
    summary: `
    Gmail: anthony.lu1115
    GitHub: @AnthonyALu
    LinkedIn: @anthony-lu-040956a1
    `,
    date: "4/7/2023",
  },
  {
    heading: "Ai Meal App",
    summary: `Goal: Create an app that is powered by ai which will tell the user a meal that they can make using the ingredients that the user inputs.`,
    date: "3/7/2023",
    status: "Done",
    link: "https://main--charming-cocada-8f0a6c.netlify.app/",
  },
  {
    heading: "Recreate Portfolio Site",
    summary: `Goal: Create a new portfolio website to showcase my skills and major projects.`,
    status: "To do",
    date: "5/7/2023",
  },
  {
    heading: "Create ThreeJS Website",
    summary: `Goal: Create a 3D website using ThreeJS.`,
    status: "To do",
    date: "5/7/2023",
  },
  {
    heading: "Build church website",
    summary: `Goal: Finish creating the new website for my local church.`,
    status: "In Progress",
    date: "1/12/2023",
  },
];
