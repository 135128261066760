import { FC, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { notesData, Note } from "./notesData";

interface IToDoContent {
  setCurrentNote: (note: Note) => void;
  searchFilter: string;
}

const ToDoContent: FC<IToDoContent> = ({ setCurrentNote, searchFilter }) => {
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const handleCardClick = (index: number) => {
    setClickedIndex(index);
    setCurrentNote(notesData[index]);
  };
  const filteredNotes =
    searchFilter.length > 0
      ? notesData.filter(
          (note) =>
            note.heading.toLowerCase().includes(searchFilter.toLowerCase()) ||
            note.summary.toLowerCase().includes(searchFilter.toLowerCase())
        )
      : notesData;
  const ToDoCard = (note: Note, index: number) => {
    const cardClicked = index === clickedIndex;
    return (
      <Flex
        minH="4.6rem"
        color="white"
        bg={cardClicked ? "yellow.400" : "gray.800"}
        borderRadius="8px"
        direction="column"
        fontSize="sm"
        gap="0.2rem"
        p="0.4rem 1rem"
        overflow="hidden"
        cursor="default"
        onClick={() => handleCardClick(index)}
      >
        <Box
          fontWeight="bold"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {note.heading}
        </Box>
        <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
          {note.date} {note.summary}
        </Box>
      </Flex>
    );
  };
  return (
    <Flex
      direction="column"
      p="0.4rem"
      gap="0.4rem"
      overflow="auto"
      h="32rem"
      maxH="100%"
      css={{
        "&::-webkit-scrollbar-thumb:vertical:hover": {
          background: "black",
          color: "blue",
        },
      }}
    >
      {filteredNotes.map((note, i) => ToDoCard(note, i))}
    </Flex>
  );
};

export default ToDoContent;
