import { Flex } from "@chakra-ui/react";
import OpenAiFoodGenerator from "./openAiFoodGenerator/openAiFoodGenerator";
import DevBlog from "./devBlog";
import GetMary from "./getMary/getMary";
import VHSLab from "./vhsLab/vhsLab";
import RamsgateChinese from "./ramsgateChinese/ramsgateChinese";

const Entries = () => {
  return (
    <Flex
      direction="column"
      w="100%"
      pb="8rem"
      justify="center"
      px="2rem"
      gap="4rem"
    >
      <DevBlog />
      <RamsgateChinese />
      <GetMary />
      <OpenAiFoodGenerator />
      <VHSLab />
    </Flex>
  );
};

export default Entries;
