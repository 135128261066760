import {
  Box,
  Button,
  Flex,
  ButtonProps,
  FlexProps,
  BoxProps,
} from "@chakra-ui/react";
import React, { FC } from "react";

interface IBanner {
  onClick?: () => void;
  buttonText?: string;
  buttonWidth?: ButtonProps["width"];
  text: string;
  title: string;
  backgroundImage: FlexProps["bgImage"];
  minHeight?: FlexProps["minHeight"];
  textOpacity?: BoxProps["opacity"];
  width?: FlexProps["width"];
  isMobile?: boolean;
  mobileWidth?: FlexProps["width"];
  textColor?: FlexProps["color"];
  textWidth?: BoxProps["maxWidth"];
  textSize?: BoxProps["fontSize"];
  textGap?: FlexProps["gap"];
  titleLabel?: React.ReactNode;
  justify?: FlexProps["justify"];
  gap?: FlexProps["gap"];
  bgSize?: FlexProps["bgSize"];
  padding?: FlexProps["padding"];
}

const Banner: FC<IBanner> = ({
  onClick = () => {
    return;
  },
  buttonText,
  buttonWidth,
  text,
  title,
  backgroundImage,
  minHeight,
  textOpacity,
  width = "100%",
  isMobile = false,
  mobileWidth = "85%",
  textGap = "2rem",
  textColor = "white.700",
  textWidth = "auto",
  titleLabel,
  textSize = "sm",
  justify,
  gap = "2rem",
  bgSize = "cover",
  padding = "4rem",
}) => {
  return (
    <Flex
      bgImage={backgroundImage}
      bgPosition="center"
      minHeight={minHeight || "155px"}
      bgSize={bgSize}
      bgRepeat="no-repeat"
      borderRadius="lg"
      p={padding}
      justify={justify ? justify : isMobile ? "end" : "space-between"}
      align="center"
      width={isMobile ? mobileWidth : width}
      direction={isMobile ? "column" : "row"}
      gap={gap}
      color={textColor}
      textAlign={isMobile ? "center" : "initial"}
    >
      <Flex direction="column" gap={textGap} justify="center">
        <Box fontSize="4xl" fontWeight="bold">
          {titleLabel || title}
        </Box>
        <Box fontSize={textSize} opacity={textOpacity || "1"} maxW={textWidth}>
          {text}
        </Box>
      </Flex>
      {buttonText && (
        <Button
          fontSize="sm"
          w={buttonWidth ?? (isMobile ? "100%" : "fit-content")}
          onClick={onClick}
          data-testid="banner-button"
        >
          <Box>{buttonText}</Box>
        </Button>
      )}
    </Flex>
  );
};

export default Banner;
