import { Box, Flex } from "@chakra-ui/react";
import TechStack from "../../components/layout/techStack";
import {
  SiTypescript,
  SiReact,
  SiOpenai,
  SiPython,
  SiChakraui,
} from "react-icons/si";
import ImageSlider from "../../components/imageSlider/imageSlider";
import SOne from "./screenshots/1.png";
import STwo from "./screenshots/2.png";
import SThree from "./screenshots/3.png";
import SFour from "./screenshots/4.png";
import SSix from "./screenshots/5.png";
import SSeven from "./screenshots/6.png";

const GetMary = () => {
  return (
    <Flex direction="column" align="center" gap="4rem">
      <Flex direction="column" align="center" gap="2rem" justify="center">
        <Box fontSize="2xl" fontWeight="bold">
          GetMary (WORK)
        </Box>
        <Box fontWeight="light" fontSize="sm">
          7th November, 2023
        </Box>
        <Box>
          <Box fontWeight="bold" display="inline">
            Description:
          </Box>{" "}
          Ai Legal Assistant Startup. GetMary's goal is to make the job of
          lawyer's easier using AI to draft legal documents.
        </Box>
        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            Position and Role:
          </Box>{" "}
          Frontend lead (contract) - Responsible for developing the frontend
          from start to MVP.
        </Box>
        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            Duration:
          </Box>{" "}
          Projected July 2023 - August 2023. Extended to Nov 2023
        </Box>
      </Flex>
      <TechStack
        items={[
          { icon: SiTypescript, skillName: "Typescript" },
          { icon: SiReact, skillName: "React" },
          { icon: SiOpenai, skillName: "Openai" },
          { icon: SiPython, skillName: "Python" },
          { icon: SiChakraui, skillName: "ChakraUi" },
        ]}
      />
      <Box fontWeight="bold" fontSize="xl" w="100%">
        Screenshots:{" "}
      </Box>
      <ImageSlider
        slides={[
          { image: SOne },
          { image: STwo },
          { image: SThree },
          { image: SFour },
          { image: SSix },
          { image: SSeven },
        ]}
      />
      <Box w="100%">
        <Box fontWeight="bold" display="inline">
          Final Comments:
        </Box>{" "}
        The project was great and the team was excellent. Although I was unable
        to continue due to the lack of funding, it was still a great experience
        working with the team on a project like this. I was able to contribute
        using my frontend skills while learning some Python for the backend.
        Overall it was a great experience.
      </Box>
    </Flex>
  );
};

export default GetMary;
