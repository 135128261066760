import { Box, Flex, Icon } from "@chakra-ui/react";
import { FC } from "react";
import { IconType } from "react-icons";

interface Item {
  icon: IconType;
  skillName: string;
}

interface ITechStack {
  items: Item[];
}

const TechStack: FC<ITechStack> = ({ items }) => {
  return (
    <Flex direction="column" align="center" gap="2rem">
      <Box fontWeight="bold" fontSize="xl">
        Tech Stack
      </Box>
      <Flex justify="center" align="center" gap="1rem">
        {items.map((item) => (
          <Flex direction="column" align="center">
            <Icon as={item.icon} boxSize="2rem" />
            <Box fontSize="xs" fontWeight="bold">
              {item.skillName}
            </Box>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default TechStack;
