import { Box, Flex } from "@chakra-ui/react";
import TechStack from "../../components/layout/techStack";
import {
  SiTypescript,
  SiReact,
  SiChakraui,
  SiGraphql,
  SiNodedotjs,
} from "react-icons/si";
import Banner from "../../components/banner";

import ZEDBanner from "./screenshots/banner-zed2-desktop.gif";

const VHSLab = () => {
  return (
    <Flex direction="column" align="center" gap="4rem">
      <Flex direction="column" align="center" gap="2rem" justify="center">
        <Box fontSize="2xl" fontWeight="bold">
          VHSLab (WORK)
        </Box>
        <Box fontWeight="light" fontSize="sm">
          11th June, 2023
        </Box>
        <Banner
          backgroundImage={ZEDBanner}
          title={"ZED Run"}
          textOpacity="70%"
          text={
            "ZED RUN is a blockchain-based game that uses NFTs to represent a horse. (Please note that the graphics used are the intellectual property of VHS.)"
          }
          textColor="white"
          textSize="md"
        />
        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            Description:
          </Box>{" "}
          Entertainment Studio Startup. Creator of ZED Run, a cryptocurrency
          horse racing application.
        </Box>
        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            Position and Role:
          </Box>{" "}
          Junior Software Engineer - Responsible for working on and delivering
          ZED Run Projects.
        </Box>
        <Box w="100%">
          <Box fontWeight="bold" display="inline">
            Duration:
          </Box>{" "}
          March 2022 - June 2023.
        </Box>
      </Flex>
      <TechStack
        items={[
          { icon: SiTypescript, skillName: "Typescript" },
          { icon: SiReact, skillName: "React" },
          { icon: SiGraphql, skillName: "GraphQL" },
          { icon: SiNodedotjs, skillName: "NodeJS" },
          { icon: SiChakraui, skillName: "ChakraUi" },
        ]}
      />

      <Box w="100%">
        <Box fontWeight="bold" display="inline">
          Final Comments:
        </Box>{" "}
        VHS marked the beginning of my journey as a software engineer. It was an
        ambitious startup with diverse projects, providing me with a valuable
        platform to expand my knowledge and skills. In my capacity as a junior
        software engineer, I contributed to the front-end development of ZED
        Run, contributed in the initial implementation of the design system, and
        laid the groundwork for the inception of ZED Arcade. My time at VHS was
        a significant learning experience, as the dynamic environment exposed me
        to multifaceted challenges and opportunities. Unfortunately, external
        factors such as shifts in cryptocurrency trends and financial
        constraints led to my departure from the company.
      </Box>
    </Flex>
  );
};

export default VHSLab;
