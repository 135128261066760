import { useState } from "react";
import "./App.css";
import { useBreakpointValue, Flex } from "@chakra-ui/react";
import Entries from "./entries/entries";
import Notepad from "./components/notepad";
import Glass from "./components/glass";

function App() {
  const [showNotepad, setShowNotepad] = useState(false);
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false,
  });
  return (
    <>
      <Glass
        isMobile={isMobile || false}
        setShowNotepad={() => setShowNotepad(!showNotepad)}
      />
      <Flex
        justify="center"
        direction={["column", "row"]}
        gap="6rem"
        align="center"
      >
        {(!isMobile || showNotepad) && (
          <Notepad
            isMobile={isMobile || false}
            setShowNotepad={() => setShowNotepad(!showNotepad)}
          />
        )}
        <Flex
          justifyContent="center"
          direction="column"
          align="center"
          w={["initial", "60%"]}
          mt="8rem"
        >
          <Entries />
        </Flex>
      </Flex>
    </>
  );
}

export default App;
