import { FC } from "react";
import { Input, InputGroup, Icon, InputLeftElement } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

interface ISearch {
  searchFilter: string;
  setSearchFilter: (filter: string) => void;
}

const Search: FC<ISearch> = ({ searchFilter, setSearchFilter }) => {
  return (
    <InputGroup>
      <Input
        placeholder="Search"
        borderRadius="md"
        border="1px"
        borderColor="gray.200"
        bg="gray.800"
        _focus={{
          borderColor: "orange.300",
          boxShadow: "outline",
        }}
        value={searchFilter}
        onChange={(event) => setSearchFilter(event.target.value)}
      />

      <InputLeftElement>
        <Icon as={FaSearch} color="gray.500" />
      </InputLeftElement>
    </InputGroup>
  );
};

export default Search;
