import { ArrowDownIcon } from "@chakra-ui/icons";
import { Box, Flex } from "@chakra-ui/react";
import { FC } from "react";
import { keyframes } from "@chakra-ui/react";

export const bounceAnimation = keyframes`
0%, 100% {
  transform: translateY(0);
}
50% {
  transform: translateY(30%);
}
`;

const DevBlog: FC = () => {
  return (
    <Flex direction="column" align="center" gap="2rem" h="100vh" pt="12rem">
      <Box fontWeight="thin" fontSize="3xl">
        Anthony Lu
      </Box>
      <Box fontSize="sm">Dev Blog and previous work updates</Box>
      <Flex align="center" gap="1rem" fontSize="sm">
        <Box pt="0.5rem">Scroll down for recent work/projects</Box>
        <Box animation={`${bounceAnimation} 1s linear infinite`}>
          <ArrowDownIcon />
        </Box>
      </Flex>
    </Flex>
  );
};

export default DevBlog;
